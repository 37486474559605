import styled, { css } from 'styled-components';
import {Modal, ModalTitle} from "react-bootstrap";
const BoldFont = "Rubik-Bold";
const MainContainer = styled.div`
width: 100%;
height: 100%;
max-width: 1246px;
margin: auto;
width: calc(100% - 50px);
max-width: 1246px;
margin: auto;

`;
const HeaderContainer = styled.div`
height: 60px;
background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
border-radius: 6px 6px 0px 0px;
display: flex;
justify-content: space-between;
padding: 10px;
align-items: center;
width: 100%;
max-width: 1246px;
margin-top: 25px;
@media (max-width: 1015px) {
  max-width: 1015px;
}
> div: first-child{
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div: first-child {
    border-radius: 50%;
    align-items: center;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    margin-right: 25px;

    > img {
      width: 100%;
    }
  }
  > div: last-child {
    font-size: 18px;
    color: #FFF;
    font-family: Rubik-Medium;
  }
}
> div: last-child {
  width: 40px;
  height: 40px;
  > img {
    width: 100%;
  }
}
`;
const SubSection = styled.div`
margin: 25px 0px;
width: 100%;
height: 100%;
display: flex;
align-items: flex-start;
max-width: 1246px;
.wrapContainer{
  display: flex;
  width: 100%;
  max-width: 823px;

  justify-content: space-between;
}
@media(max-width: 1250px) {
  display: flex;
  flex-wrap: wrap;
}
@media(max-width: 688px) {
  display: block;
}
`;
const CalendarContainer = styled.div`
* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

body {
  font-family: "Lato", sans-serif;
}

* {
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  background-color: #dfe6e9;
  width: 26vw;
  height: 42vh;
  display: flex;
}

.calendar {
  margin: auto;
  width: 600px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.2);

}

.month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px 30px;
  text-align: center;
  background-color: #2ecc71;
  color: #fff;
}

.weekdays {
  background-color: #27ae60;
  color: #fff;
  padding: 7px 0;
  display: flex;
}

.days {
  font-weight: 300;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.weekdays div,
.days div {
  text-align: center;
  width: 14.28%;
}

.days div {
  padding: 10px 0;
  margin-bottom: 10px;
  transition: all 0.4s;
}

.prev_date {
  color: #999;
}

.today {
  background-color: #27ae60;
  color: #fff;
}

.days div:hover {
  cursor: pointer;
  background-color: #dfe6e9;
}

.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 23px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.prev:hover,
.next:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

#month {
  font-size: 30px;
  font-weight: 500;
}

  max-width: 400px;   
  min-width: 340px;
  max-width: 400px
border-radius: 6px;
left: 448px;
top: 184px;
justify-content: flex-start;
float: left;

@media(min-width: 1550px) {
  width: 100%;
}
@media(max-width: 1450px) and (min-width: 1300px){
  width: 100%;
}
@media(max-width: 1250px) {
  width: 100%;
}
  .react-calendar
  {
     
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    @media(max-width: 1300px) and (min-width: 1250px){
      width: 322px;
    }
    .react-calendar__navigation
    {
      padding: 10px 20px;
      height: 15%;
      align-items: center;
      justify-content: space-between;

      button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button
      {
        border: none;
        background-color: white;
        display: none;
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button
      {
        border: none;
        background-color: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #69C2FF;
        color: white;

        &:focus {
          outline: none;
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next-button
      {
        border: none;
        background-color: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #69C2FF;
        color: white;

        &:focus {
          outline: none;
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button
      {

        border: none;
        background-color: white;
        display: none;
      }
      .react-calendar__navigation__label
      {
        border: none;
        background: linear-gradient(90deg, rgba(0, 177, 188, 0.5) -1.11%, rgba(117, 217, 163, 0.5) 97.46%);
        border-radius: 24px;
        max-width: 200px;
        min-height: 30px;

        &:focus {
          outline: none;
        }
        span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from
        {
          font-size: 18px;
          font-family: 'Rubik-Medium';
          background: #0D4270;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .react-calendar__viewContainer
    {
      padding: 10px;
      width: 100%;
      height: 85%;

      @media(max-width: 1250px) {
        padding: 0px;
      }

      .react-calendar__month-view
      {
        width: 100%;
        height: 100%;
        background-color: #FFF;

        .react-calendar__month-view__weekdays
        {
          .react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__weekdays__weekday
          {
            border-bottom: 4px solid #000;
          }
          .react-calendar__month-view__weekdays__weekday
          {
            padding-bottom: 10px;
            border-bottom: 4px solid #AFAFAF;
            text-align: center;
            abbr[title]
            {
              border-bottom: none;
              text-decoration: none;
              color: #AFAFAF;
            }
          }
        }
        .react-calendar__month-view__days
        {
          margin-top: 8px;
          justify-content: space-evenly;
          background-color: #FFF;
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day
          {
            background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin: 4px;

            &:focus {
              outline: none;
            }

            > abbr
            {
              font-size: 13px;
              line-height: 19px;
              color: #FFF;
              text-align: center;
              font-family: Rubik;
            }

            @media(min-width: 1550px) {
              margin: 11px;
            }
            @media(max-width: 1450px) and (min-width: 1300px){
              margin: 7px;
            }
            @media(max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media(max-width: 1223px) {
              margin: 13px;
            }
            @media(max-width: 1195px) {
              margin: 12px;
            }
            @media(max-width: 1167px) {
              margin: 11px;
            }
            @media(max-width: 1059px) {
              margin: 10px;
            }
            @media(max-width: 1046px) {
              margin: 9px;
            }
            @media(max-width: 1003px) {
              margin: 8px;
            }
            @media(max-width: 975px) {
              margin: 7px;
            }
            @media(max-width: 950px) {
              margin: 15px;
            }
            @media(max-width: 931px) {
              margin: 14px;
            }
            @media(max-width: 903px) {
              margin: 13px;
            }
            @media(max-width: 875px) {
              margin: 12px;
            }
            @media(max-width: 847px) {
              margin: 11px;
            }
            @media(max-width: 819px) {
              margin: 10px;
            }
            @media(max-width: 791px) {
              margin: 9px;
            }
            @media(max-width: 763px) {
              margin: 8px;
            }
            @media(max-width: 735px) {
              margin: 7px;
            }
            @media(max-width: 707px) {
              margin: 6px;
            }
            @media(max-width: 688px) {
              margin: 28px;
            }
            @media(max-width: 642px) {
              margin: 27px;
            }
            @media(max-width: 628px) {
              margin: 25px;
            }
            @media(max-width: 600px) {
              margin: 24px;
            }
            @media(max-width: 586px) {
              margin: 23px;
            }
            @media(max-width: 572px) {
              margin: 22px;
            }
            @media(max-width: 559px) {
              margin: 21px;
            }
            @media(max-width: 544px) {
              margin: 20px;
            }
            @media(max-width: 530px) {
              margin: 19px;
            }
            @media(max-width: 516px) {
              margin: 18px;
            }
            @media(max-width: 502px) {
              margin: 17px;
            }
            @media(max-width: 488px) {
              margin: 16px;
            }
            @media(max-width: 474px) {
              margin: 15px;
            }
            @media(max-width: 460px) {
              margin: 14px;
            }
            @media(max-width: 446px) {
              margin: 13px;
            }
            @media(max-width: 432px) {
              margin: 12px;
            }
            @media(max-width: 418px) {
              margin: 11px;
            }
            @media(max-width: 404px) {
              margin: 10px;
            }
            @media(max-width: 390px) {
              margin: 9px;
            }
            @media(max-width: 376px) {
              margin: 8px;
            }
            @media(max-width: 362px) {
              margin: 7px;
            }
            @media(max-width: 348px) {
              margin: 6px;
            }
            @media(max-width: 334px) {
              margin: 5px;
            }
            @media(max-width: 320px) {
              margin: 4px;
            }
          }
          ${({bgColor}) => bgColor && css`
            button.react-calendar__tile.react-calendar__tile--active {
              background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%) !important;
              color: white !important;
              border: none !important;

              > abbr
              {
                font-size: 13px !important;
                line-height: 19px;
                color: #FFF !important;
                text-align: center;
                font-family: Rubik;
              }

            }
          `}
          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth
          {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            border: 3px solid #D8D8D8;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin: 4px;

            &:focus {
              outline: none;
            }

            @media(min-width: 1550px) {
              margin: 11px;
            }
            @media(max-width: 1450px) and (min-width: 1300px){
              margin: 7px;
            }
            @media(max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media(max-width: 1223px) {
              margin: 13px;
            }
            @media(max-width: 1195px) {
              margin: 12px;
            }
            @media(max-width: 1167px) {
              margin: 11px;
            }
            @media(max-width: 1059px) {
              margin: 10px;
            }
            @media(max-width: 1046px) {
              margin: 9px;
            }
            @media(max-width: 1003px) {
              margin: 8px;
            }
            @media(max-width: 975px) {
              margin: 7px;
            }
            @media(max-width: 950px) {
              margin: 15px;
            }
            @media(max-width: 931px) {
              margin: 14px;
            }
            @media(max-width: 903px) {
              margin: 13px;
            }
            @media(max-width: 875px) {
              margin: 12px;
            }
            @media(max-width: 847px) {
              margin: 11px;
            }
            @media(max-width: 819px) {
              margin: 10px;
            }
            @media(max-width: 791px) {
              margin: 9px;
            }
            @media(max-width: 763px) {
              margin: 8px;
            }
            @media(max-width: 735px) {
              margin: 7px;
            }
            @media(max-width: 707px) {
              margin: 6px;
            }
            @media(max-width: 688px) {
              margin: 28px;
            }
            @media(max-width: 642px) {
              margin: 27px;
            }
            @media(max-width: 628px) {
              margin: 25px;
            }
            @media(max-width: 600px) {
              margin: 24px;
            }
            @media(max-width: 586px) {
              margin: 23px;
            }
            @media(max-width: 572px) {
              margin: 22px;
            }
            @media(max-width: 559px) {
              margin: 21px;
            }
            @media(max-width: 544px) {
              margin: 20px;
            }
            @media(max-width: 530px) {
              margin: 19px;
            }
            @media(max-width: 516px) {
              margin: 18px;
            }
            @media(max-width: 502px) {
              margin: 17px;
            }
            @media(max-width: 488px) {
              margin: 16px;
            }
            @media(max-width: 474px) {
              margin: 15px;
            }
            @media(max-width: 460px) {
              margin: 14px;
            }
            @media(max-width: 446px) {
              margin: 13px;
            }
            @media(max-width: 432px) {
              margin: 12px;
            }
            @media(max-width: 418px) {
              margin: 11px;
            }
            @media(max-width: 404px) {
              margin: 10px;
            }
            @media(max-width: 390px) {
              margin: 9px;
            }
            @media(max-width: 376px) {
              margin: 8px;
            }
            @media(max-width: 362px) {
              margin: 7px;
            }
            @media(max-width: 348px) {
              margin: 6px;
            }
            @media(max-width: 334px) {
              margin: 5px;
            }
            @media(max-width: 320px) {
              margin: 4px;
            }
          }
          button.react-calendar__tile.react-calendar__month-view__days__day
          {
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            background-color: white;
            border: 3px solid #D8D8D8;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin: 4px;

            &:focus {
              outline: none;
            }

            @media(min-width: 1550px) {
              margin: 11px;
            }
            @media(max-width: 1450px) and (min-width: 1300px){
              margin: 7px;
            }
            @media(max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media(max-width: 1223px) {
              margin: 13px;
            }
            @media(max-width: 1195px) {
              margin: 12px;
            }
            @media(max-width: 1167px) {
              margin: 11px;
            }
            @media(max-width: 1059px) {
              margin: 10px;
            }
            @media(max-width: 1046px) {
              margin: 9px;
            }
            @media(max-width: 1003px) {
              margin: 8px;
            }
            @media(max-width: 975px) {
              margin: 7px;
            }
            @media(max-width: 950px) {
              margin: 15px;
            }
            @media(max-width: 931px) {
              margin: 14px;
            }
            @media(max-width: 903px) {
              margin: 13px;
            }
            @media(max-width: 875px) {
              margin: 12px;
            }
            @media(max-width: 847px) {
              margin: 11px;
            }
            @media(max-width: 819px) {
              margin: 10px;
            }
            @media(max-width: 791px) {
              margin: 9px;
            }
            @media(max-width: 763px) {
              margin: 8px;
            }
            @media(max-width: 735px) {
              margin: 7px;
            }
            @media(max-width: 707px) {
              margin: 6px;
            }
            @media(max-width: 688px) {
              margin: 28px;
            }
            @media(max-width: 642px) {
              margin: 27px;
            }
            @media(max-width: 628px) {
              margin: 25px;
            }
            @media(max-width: 600px) {
              margin: 24px;
            }
            @media(max-width: 586px) {
              margin: 23px;
            }
            @media(max-width: 572px) {
              margin: 22px;
            }
            @media(max-width: 559px) {
              margin: 21px;
            }
            @media(max-width: 544px) {
              margin: 20px;
            }
            @media(max-width: 530px) {
              margin: 19px;
            }
            @media(max-width: 516px) {
              margin: 18px;
            }
            @media(max-width: 502px) {
              margin: 17px;
            }
            @media(max-width: 488px) {
              margin: 16px;
            }
            @media(max-width: 474px) {
              margin: 15px;
            }
            @media(max-width: 460px) {
              margin: 14px;
            }
            @media(max-width: 446px) {
              margin: 13px;
            }
            @media(max-width: 432px) {
              margin: 12px;
            }
            @media(max-width: 418px) {
              margin: 11px;
            }
            @media(max-width: 404px) {
              margin: 10px;
            }
            @media(max-width: 390px) {
              margin: 9px;
            }
            @media(max-width: 376px) {
              margin: 8px;
            }
            @media(max-width: 362px) {
              margin: 7px;
            }
            @media(max-width: 348px) {
              margin: 6px;
            }
            @media(max-width: 334px) {
              margin: 5px;
            }
            @media(max-width: 320px) {
              margin: 4px;
            }

            > abbr
            {
              font-size: 13px;
              line-height: 19px;
              color: #000000;
              text-align: center;
              font-family: Rubik;
            }
          }
        }
      }
      .react-calendar__year-view
      {
        width: 100%;
        height: 100%;

        .react-calendar__year-view__months
        {
          height: 100%;
          width: 100%;
          justify-content: space-evenly;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month
          {
            border: none;
            background-color: #FFF;
            font-size: 16px;
            font-family: 'Rubik-Medium';
            background: -webkit-linear-gradient(90deg,#006FBC -1.11%,#7F43FF 97.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__year-view__months__month {
            border: none;
            background-color: #FFF;
            color: #000000;
            font-size: 16px;
            font-family: 'Rubik-Regular';

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__decade-view
      {
        width: 100%;
        height: 100%;

        .react-calendar__decade-view__years
        {
          width: 100%;
          height: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year
          {
            border: none;
            background-color: #FFF;
            font-size: 16px;
            font-family: 'Rubik-Medium';
            background: -webkit-linear-gradient(90deg,#006FBC -1.11%,#7F43FF 97.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__decade-view__years__year
          {
            border: none;
            background-color: #FFF;
            color: #000000;
            font-size: 16px;
            font-family: 'Rubik-Regular';

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__century-view
      {
        height: 100%;
        width: 100%;

        .react-calendar__century-view__decades
        {
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade
          {
            border: none;
            background-color: #FFF;
            font-size: 16px;
            font-family: 'Rubik-Medium';
            background: -webkit-linear-gradient(90deg,#006FBC -1.11%,#7F43FF 97.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__century-view__decades__decade
          {
            border: none;
            background-color: #FFF;
            color: #000000;
            font-size: 16px;
            font-family: 'Rubik-Regular';

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
`;
const DayWorkOut = styled.div`
width: 100%;
max-width: 100%;
margin-top: 15px;
background-color: #FFF;
border-radius: 6px;
padding: ${({padding}) => padding ? '15px 15px 0px 15px;' : '0px 0px'};
display: flex;
justify-content: space-between;
flex-flow: column;
height: 100%;
float: left;
min-width: 326px;
max-width: 400px;

@media(min-width: 1550px) {
  padding: ${({padding}) => padding ? '15px 15px 0px 15px;' : '0px'};
}
@media(max-width: 1450px) and (min-width: 1300px){
  padding: ${({padding}) => padding ? '15px 15px 0px 15px;' : '0px 5px'};
}
@media(max-width: 1250px) {
  padding: ${({padding}) => padding ? '15px 15px 0px 15px;' : '0px 5px'};
}
`;
const Title = styled.div`
color: #0D4270;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
`;
const Card = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #ECECEC;
min-height: 90px;
flex-wrap:wrap;
padding-bottom:10px;
.iconCard {
  border-radius: 6px;
  background: ${({intensityCheck}) => (intensityCheck === 0) ? 'linear-gradient(180deg, #FD7175 0%, #FF5257 100%)' : 'linear-gradient(180deg, #F6B479 0%, #FFA24E 100%)'};
}
> div: first-child {
	width: calc(19% - 10px);
	height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  margin-right: 10px;
  
  > img {
    width: 40px;
  }
}
> div: last-child {
  width: 81%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: auto;
  margin-top: 15px;
  > div: first-child {
    color: #0D4270;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
  }
}
&: last-child {
  border-bottom: none;
}
`;
const ActivityDetails = styled.div`
width: 100%;
display: flex;
flex-flow: row;
justify-content: space-between;
margin-top: ${({margin}) => margin ? '0px' : '10px'};
`;
const Details = styled.div`
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
.counterContainer {
  display: flex;
  align-items: center;
  color: #9c9c9c;
  .counterImage {
    margin-right: 5px;
  }
  .counterTitle {
    color: #9c9c9c;
  }
}
> div: first-child {
  margin-right: ${({marginRight}) => marginRight ? '2px' : '5px'};
  > img {
    width: 17px;
  }
}
> div: last-child {
  color: #858585;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Rubik';
}
`;
const WorkoutContainer = styled.div`
    width: 100%;
    margin-bottom: 15px;
    float: left;
    height: 100%;
height: 100%;

> div: first-child {
  text-transform: capitalize;
  color: #0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
}
@media(max-width: 1250px) {
  margin-top: 15px;
  float: left;
  width: calc((100% - 30px) / 2);
  margin-right: 0px;
}
@media(max-width: 688px) {
  float: left;
  margin-top: 15px;
  width: 100%;
  height: 100%;
}
`;
const SubContainer = styled.div`
width: 100%;
height: 468px;
background: #FFFFFF;
border-radius: 6px;
height: 100%;
`;
const DisplayFlex = styled.div`
display: block;
flex-flow: column;
float: left;
margin-right: 25px;
justify-content: center;
align-items: center;
float: left;
width: 400px;
@media(min-width: 1550px) {
  justify-content: center;
  align-items: center;
  width: 400px;
}
@media(max-width: 1450px) and (min-width: 1300px){
  width: 358px;
  width: 50%;
  justify-content: center;
  align-items: center;
}
@media(max-width: 1250px) {
  float: left;
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
  height: 100%;
}
@media(max-width: 688px) {
  float: left;
  width: 100%;
  height: 100%;
}
`;
const CardSection = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
border-bottom: 1px solid #ECECEC;
min-height: 100px;
padding: 15px 0px;

/* min-height: ${({ minhieght }) => minhieght ? "200px" : "110px"}; */


.iconCard {
  border-radius: 6px;
  background: ${({intensityCheck}) => (intensityCheck === 0) ? 'linear-gradient(180deg, #FD7175 0%, #FF5257 100%)' : 'linear-gradient(180deg, #F6B479 0%, #FFA24E 100%)'};
}
> div: first-child {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-left: 20px;
  margin-right: 5px;
  min-height: 60px;
  border-radius: 6px;
  margin: 0px 5px auto 15px;
  
  > img {
    width: 40px;
   height: 40px;
   left: 464px;
   top: 570px;

  }
}
> div: last-child {
  width: 80%;
  display: flex;
  flex-flow: column;
	justify-content: flex-start;
	margin-left: 5px;
  > div: nth-child(2) {
    color: #0D4270;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
}
&: last-child {
  border-bottom: none;
}
`;
const TimeContainer = styled.div`
color: #858585;
font-size: 12px;
line-height: 14px;
display: flex;
justify-content: space-between;
`;


const Bold = styled.div`
text-align: center;
margin-bottom: 25px;
margin-top: 25px;
font-size: 18px;
.subContent {
  text-align: center;
  color: rgb(153,153,153);
  font-size: 12px;
  margin-top: 5px;
}
`;


const StyledModal = styled(Modal)`
.modal-content{
  border-radius: 7px;
}
.modal-backdrop.in{
  opacity: .2;
}
.modal-dialog {
  transform: translate(-50%, -50%);
}
.delete-popup { 
  top: 30%;
  width: 400px;
  height: 90px;
  margin: 0 auto;
  .modal-header {
    border-bottom: 0px;
    background: linear-gradient( 
      180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0 0;
  } 
}
@media (max-width: 500px){
 .modal-dialog {
  width: 80%;
  }
}
`;

const StyledBody = styled(Modal.Body)`
  padding: 0px 25px 25px 25px;
`;
const SubmitContainer = styled.div`
width: 100%;
margin-bottom: 20px;
display: flex;
justify-content: center;
align-items: center;

.biometric-button {
  font-size: 16px;
}
`;

const UserButton = styled.button`
background-color: ${({bgColor}) => bgColor ? bgColor: ''};
text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
cursor: pointer;
color: ${({textColor}) => textColor ? textColor : '#9c9c9c'};
white-space: nowrap;
padding: ${({padding}) => padding ? padding : '4px 7px'}
width: ${({width}) => width ? width: '100%'};
font-size: ${({fontSize}) => fontSize ? fontSize : '9px'}
line-height: 1.428571429;
border-radius:  ${({radius}) => radius ? radius : '5px'};
font-family:  ${({font}) => font ? font : `${BoldFont}`};
font-weight: ${({font}) => font && '600'};
margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
float: ${({float}) => float ? 'left': 'right'};
margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
text-align: ${({Align}) => Align ? 'center': 'left'};
opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
border: ${({border}) => border ? '1px solid lightgray': 'none'};
height: ${({ height }) => height ? height : '46px'};
box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};

img {
  height: 18px;
  width: 18px;
}

&:hover, &:active, &:focus{
  outline: ${({outline}) => outline ? '' : 'none'};
}

.send 
{
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
}
`;

const Newwork = styled.div`
  display :flex ;
  justify-content:space-between;

  > div: first-child{
    margin: 4px 0px; 
    line-height: 14px;
    margin-top: 0px;
  }
  > div: last-child{
    margin: auto;
    margin-right: 0px;
    margin-top: 0px;
  }
  
`;

const NewButtons = styled.div`
display:flex;
justify-content:center;
margin-top:7px; 
margin-left:80px;  

`;

const ButtonContainer = styled.div`
display:flex;
width:100%;

`;

const StyledTitle = styled(ModalTitle)`
  font-size: 18px;
  letter-spacing: 0px;
  color: white;
  font-family: 'rubik-medium';
  text-align: center;
`;

const WorkNew = styled.div`
  display :flex ;
  justify-content:space-between;

  > div: first-child{
    line-height: 24px;
    margin-top: 0px;
  } 
`;

export {
  HeaderContainer, MainContainer, SubSection, CalendarContainer, DayWorkOut, Title, Card, ActivityDetails,
  Details, WorkoutContainer, SubContainer, DisplayFlex, CardSection, TimeContainer,Bold, StyledModal, StyledBody, SubmitContainer,UserButton ,Newwork,NewButtons,ButtonContainer,StyledTitle, WorkNew
}