import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MainContainer, HeaderContainer, SubSection } from './styles';
import WeeklyCalendar from './WeeklyCalendar';
import DayWiseWorkOut from './DayWiseWorkOut';
import MyMonthlyActivity from './MyMonthlyWorkout';
import { getDailyWorkoutHistory, getworkoutActivityByActivityType } from '../../redux/actions';
import SingleActivity from './SingleActivity';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class WorkoutHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWorkoutActivity: false,
      typeOfActivity: ''
    }
  }

  componentDidMount() {
    const { getDailyWorkoutHistory } = this.props;
    getDailyWorkoutHistory();
  }

  hideWorkoutactivity = (activityType, selectedYear) => {
    const { getworkoutActivityByActivityType } = this.props;
    this.setState({
      showWorkoutActivity: true,
      typeOfActivity: activityType
    }, () => getworkoutActivityByActivityType(activityType, selectedYear));
  }

  displayWorkoutActivity = () => {
    this.setState({
      showWorkoutActivity: false
    })
  }

  render() {
    const { showWorkoutActivity, typeOfActivity } = this.state;
    const { dayWiseWorkoutHistory, workoutActivityBytypes, t } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

        <MainContainer>

          <HeaderContainer>
            <div>
              <div >
                <img onClick={()=>this.props.history.push({pathname: '/profile',query: {isworkoutHistory:true}})} style={{margin: '0px 0px 0px 10px', cursor: 'pointer'}} src='/public/images/backcorevalueButton.png'></img>
                {/* <img src="/images/NewDashboardV2/workoutImg1.png" style={{marginLeft:'20px'}}/> */}
              </div>
              <div >
                {t("Workout History")}
              </div>
            </div>
            <div />
          </HeaderContainer>
          <SubSection>
            <WeeklyCalendar />
            <div className="wrapContainer">
              <MyMonthlyActivity hideWorkoutactivity={this.hideWorkoutactivity} dayWiseWorkoutHistory={MyMonthlyActivity} displayWorkoutActivity={this.displayWorkoutActivity} />
              {showWorkoutActivity === false ?
                <DayWiseWorkOut dayWiseWorkoutHistory={dayWiseWorkoutHistory} /> :
                <SingleActivity workoutActivityBytypes={workoutActivityBytypes} typeOfActivity={typeOfActivity} />}
            </div>
          </SubSection>
        </MainContainer>
      </div>
    );
  }
}

WorkoutHistory.propTypes = {
  history: PropTypes.object.isRequired,
  getDailyWorkoutHistory: PropTypes.func,
  dayWiseWorkoutHistory: PropTypes.object,
  showWorkoutActivity: PropTypes.bool,
  getworkoutActivityByActivityType: PropTypes.func,
  workoutActivityBytypes: PropTypes.object,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  dayWiseWorkoutHistory: state.social.dayWiseWorkoutHistory,
  workoutActivityBytypes: state.social.workoutActivityBytypes
});

const mapDispatchToProps = (dispatch) => ({
  getDailyWorkoutHistory: () => dispatch(getDailyWorkoutHistory()),
  getworkoutActivityByActivityType: (activityType, selectedYear) => dispatch(getworkoutActivityByActivityType(activityType, selectedYear)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WorkoutHistory)));
